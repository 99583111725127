import { Button, ConfigProvider } from 'antd';
import React from 'react';
import ReactPlayer from 'react-player';
import {
  TEXT_ALIGNMENTS,
  TEXT_ALIGNMENTS_CLASS
} from '../../../../common/constants';
import webStyle from '../../../../styles/style-web.json';

function HeroBlock({
  imageSrc,
  title,
  description,
  date,
  location,
  cta1,
  cta2,
  responsive,
  videoSrc,
  textAlignment = TEXT_ALIGNMENTS.LEFT
}) {
  const alignment = TEXT_ALIGNMENTS_CLASS[textAlignment];
  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`relative heroblock bg-n-1000 ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        {imageSrc ? <img src={imageSrc} alt="images" /> : null}
        {videoSrc ? (
          <ReactPlayer
            url={videoSrc}
            controls={false}
            playing
            loop
            muted
            width="100%"
            height="100%"
          />
        ) : null}

        <div className="container">
          <div className="herocontent relative ">
            {title ? (
              <pre className={`text-n-0 title mb-16 ${alignment}`}>{title}</pre>
            ) : null}
            {description ? (
              <pre className={`description text-n-100 mb-16 ${alignment}`}>
                {description}
              </pre>
            ) : null}
            {date ? (
              <div className={`date-heading text-n-100 ${alignment}`}>
                {date}
              </div>
            ) : null}
            {location ? (
              <div className={`date-heading text-n-100 ${alignment}`}>
                {location}
              </div>
            ) : null}
            {cta1 || cta2 ? (
              <div className="hero-button-wrapper">
                {cta2 ? (
                  <Button
                    className="cs-button bg-n-100 d-flex align-center hbg-n-900 hc-n-100 hb-n-900"
                    size="large"
                  >
                    {cta2}
                  </Button>
                ) : null}
                {cta1 ? (
                  <Button
                    className="cs-button bg-n-100 d-flex align-center hbg-n-900 hc-n-100 hb-n-900"
                    size="large"
                  >
                    {cta1}
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default HeroBlock;
